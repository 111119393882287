@use "mixins";
@use "var";

.wrapper {
    min-width: var.$media-xs - 20;
    padding: 0 16px;
    margin: 0 auto;

    /**
    * @deprecated Нужно будет удалить
     */
    &_desktop {
        padding: 0;
    }
}

.bleed {
    padding: 0 16px;
    margin: 0 -16px;
}

@include mixins.media(s) {
    .wrapper {
        /**
        * @deprecated Нужно будет удалить
         */
        &_desktop {
            padding: 0 16px;
        }
    }
}

@include mixins.media(l) {
    .wrapper {
        max-width: var.$media-l + 32;
        padding: 0 16px;
    }
}
