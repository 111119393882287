/**
    * divider
 */
@mixin divider() {
    border-bottom: 2px dotted var(--grey);
}

@mixin removeDivider() {
    border-bottom: unset;
}
