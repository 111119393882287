lightbox {
    box-shadow: none !important;
    gallery {
        background-color: var(--white) !important;
    }
    .g-btn-close {
        top: 10px;
        right: 10px;
        background-color: rgba(0, 0, 0, 0.5);
        background-image: url("../images/i/i_appClose2-m.svg");
        background-repeat: no-repeat;
        background-position: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        transition: all 0.2s ease-in-out;
        svg {
            display: none;
        }
        &:hover {
            background-color: @black;
        }
        &:active {
            background-color: @black;
        }
    }
    .g-nav-prev {
        display: block;
        position: absolute;
        width: 30px;
        height: 50px;
        background-color: rgba(0, 0, 0, 0.5);
        top: 50%;
        margin-top: -25px;
        z-index: 20;
        font-size: 0;
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        transform: translate(0, 0) !important;
        left: -1px;
        border-radius: 0 25px 25px 0;
        &:before {
            content: "";
            position: absolute;
            width: 9px;
            height: 15px;
            top: 50%;
            margin-top: -7px;
            background-image: url("../images/mobile/arrow.png");
            background-repeat: no-repeat;
            background-size: cover;
            left: 8px;
            background-position: left 0;
        }
        &:hover {
            background-color: @black;
        }
        &:active {
            background-color: @black;
        }
        svg {
            display: none;
        }
    }
    .g-nav-next {
        display: block;
        position: absolute;
        width: 30px;
        height: 50px;
        background-color: rgba(0, 0, 0, 0.5);
        top: 50%;
        margin-top: -25px;
        z-index: 20;
        font-size: 0;
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        transform: translate(0, 0) !important;
        right: -1px;
        border-radius: 25px 0 0 25px;
        &:before {
            content: "";
            position: absolute;
            width: 9px;
            height: 15px;
            top: 50%;
            margin-top: -7px;
            background-image: url("../images/mobile/arrow.png");
            background-repeat: no-repeat;
            background-size: cover;
            right: 8px;
            background-position: right 0;
        }
        &:hover {
            background-color: @black;
        }
        &:active {
            background-color: @black;
        }
        svg {
            display: none;
        }
    }

    @media (min-width: @mw-m-up) {
        html#desktop & {
            position: static !important;
            gallery {
                position: static !important;
            }
            gallery-core,
            .g-box,
            gallery-slider {
                position: static !important;
                background-color: var(--white) !important;
            }
        }
    }
}
