input {
    -webkit-appearance: none !important;
    border-radius: 0;
    line-height: normal;
    font-size: 16px;
    &:focus {
        font-size: 16px;
    }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield !important;
}

input.form {
    width: 100%;
    font-family: @mont, sans-serif;
    color: #41434c;
    font-size: 30px;
    line-height: 1;
    padding: 4px 10px 3px;
    background: @white;
    .placeholder(#838383);
    border-radius: 5px;
    border: 3px solid #c2c2c2;
    text-align: center;
    &:hover {
        border-color: #a6e2d8;
    }
    &:focus {
        border-color: #a6e2d8;
    }
    &.error {
        color: @red!important;
        .placeholder(@red);
        border-color: @red;
    }

    @media (min-width: @mw-m-up) {
    }
}

input.form6 {
    // +
    width: 100%;
    font-family: @mont, sans-serif;
    font-weight: 500;
    color: @color;
    font-size: 13px;
    line-height: 1;
    padding: 9px 14px 9px;
    border: 1px solid #f0f0f0;
    transition: all 0.3s ease-in-out;
    background: #f0f0f0;
    .placeholderM(@gray2);
    border-radius: 6px;
    max-height: 36px;
    &:active {
        border-color: #d4d4d4;
        background: @white;
    }
    &:focus {
        border-color: #d4d4d4;
        background: @white;
    }
    &.error {
        border-color: @red!important;
        color: @red!important;
        .placeholderM(@red);
    }

    @media (min-width: @mw-m-up) {
        font-size: 15px;
        padding: 10px 17px;
        border: 1px solid #f0f0f0;
        background: #f0f0f0;
        .placeholderM(@gray2);
        border-radius: 6px;
        max-height: 40px;
        &:active {
            border-color: #d4d4d4;
            background: @white;
        }
        &:focus {
            border-color: #d4d4d4;
            background: @white;
        }
        &.error {
            border-color: @red!important;
            color: @red!important;
            .placeholderM(@red);
        }
    }
}

input.form8 {
    // +
    width: 100%;
    font-family: @mont, sans-serif;
    color: @color;
    font-size: 13px;
    line-height: 1;
    padding: 9px 14px 9px;
    border: 1px solid #f0f0f0;
    transition: all 0.3s ease-in-out;
    background: #f0f0f0;
    .placeholderM(@color);
    border-radius: 6px;
    max-height: 36px;
    &:active {
        border-color: #b9b9b9;
    }
    &:focus {
        border-color: #b9b9b9;
    }
    &.error {
        border-color: @red!important;
        color: @red!important;
        .placeholderM(@red);
    }

    @media (min-width: @mw-m-up) {
        font-size: 15px;
        padding: 9px 16px 8px 16px;
        border-radius: 6px;
        max-height: 40px;
        min-height: 40px;
        border: 2px solid #d4d4d4;
        background: #ffffff;
        .placeholderM(#9a9a9a);
        &.error {
            border-color: @red!important;
            color: @red!important;
            .placeholderM(@red);
        }
    }
}

input.formMobileLanding {
    // +
    @media (min-width: @mw-m-up) {
        background: white;
        border: 2px solid #c4c4c4;
        border-radius: 30px;
        line-height: 62px;
        height: 62px;
        width: 320px;
        font-family: @mont, sans-serif;
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        padding: 0 20px 0 56px;
        &::-webkit-input-placeholder {
            opacity: 1;
            -webkit-transition: all 0.2s ease-in-out;
            transition: all 0.2s ease-in-out;
            color: #c4c4c4;
        }
        &::-moz-placeholder {
            opacity: 1;
            color: #c4c4c4;
        }
        &:-moz-placeholder {
            opacity: 1;
            color: #c4c4c4;
        }
        &:-ms-input-placeholder {
            opacity: 1;
            -o-transition: all 0.2s ease-in-out;
            transition: all 0.2s ease-in-out;
            color: #c4c4c4;
        }
        &.error {
            border: 2px solid @red!important;
            color: @red!important;
            background: #fff3e8;
            &::-webkit-input-placeholder {
                opacity: 1;
                -webkit-transition: all 0.2s ease-in-out;
                transition: all 0.2s ease-in-out;
                color: @red;
            }
            &::-moz-placeholder {
                opacity: 1;
                color: @red;
            }
            &:-moz-placeholder {
                opacity: 1;
                color: @red;
            }
            &:-ms-input-placeholder {
                opacity: 1;
                -o-transition: all 0.2s ease-in-out;
                transition: all 0.2s ease-in-out;
                color: @red;
            }
        }
    }
}

/*------New------*/
input[readonly] {
    cursor: no-drop;
    &.ng-touched,
    &.ng-untouched {
        cursor: pointer;
    }
}
input.megaInput {
    width: 100%;
    font-family: @mont, sans-serif;
    color: @black;
    font-size: 13px;
    line-height: 1;
    padding: 8px 14px;
    border: 2px solid #d4d4d4;
    transition: all 0.3s ease-in-out;
    background: @white;
    max-height: 36px;
    min-height: 36px;
    font-weight: 500;
    .placeholderM(@gray2);
    border-radius: 18px;
    &:active {
        border-color: #d4d4d4;
    }
    &:focus {
        border-color: #d4d4d4;
    }
    &.error {
        border-color: @red!important;
        color: @red!important;
        .placeholderM(@red);
    }
    &.h50 {
        max-height: 45px;
        min-height: 45px;
        padding: 13px 14px 12px;
        border-radius: 25px;
    }
    &.h40 {
        max-height: 40px;
        min-height: 40px;
        padding: 8px 20px 9px;
        border-radius: 25px;
        font-size: 15px;
    }
    &.h36 {
        max-height: 36px;
        min-height: 36px;
        padding: 6px 18px 7px;
        border-radius: 25px;
        font-weight: bold;
        font-size: 10px;
    }

    @media (min-width: @mw-m-up) {
        font-size: 15px;
        max-height: 40px;
        min-height: 40px;
        border-radius: 30px;
        padding: 9px 24px 8px;

        &.h50 {
            max-height: 50px;
            min-height: 50px;
            padding: 14px 24px 13px;
        }
        &.h40 {
        }
        &.h36 {
            max-height: 36px;
            min-height: 36px;
            padding: 6px 18px 6px;
            font-size: 10px;
        }
    }
}
input.mInput {
    width: 100%;
    font-family: @mont, sans-serif;
    color: @black;
    line-height: 1;
    border: 2px solid #d4d4d4;
    transition: all 0.3s ease-in-out;
    background: @white;
    font-weight: 500;
    .placeholderM(@gray2);
    border-radius: 25px;
    padding: 0 20px;
    &:active {
        border-color: #d4d4d4;
    }
    &:focus {
        border-color: #d4d4d4;
    }
    &.error {
        border-color: @red!important;
        color: @red!important;
        .placeholderM(@red);
    }

    &.s-s_45 {
        max-height: 45px;
        min-height: 45px;
        line-height: 45px;
    }
    &.s-s_50 {
        max-height: 50px;
        min-height: 50px;
        line-height: 50px;
    }
    &.f-s_13 {
        font-size: 13px;
    }
    &.f-s_15 {
        font-size: 15px;
    }
    &.b_white {
        border-color: @white;
        background-color: @white;
        color: @black;
        .placeholderM(@gray);
    }

    @media (min-width: @mw-m-up) {
        padding: 0 25px;
        &.s-m_45 {
            max-height: 45px;
            min-height: 45px;
            line-height: 45px;
        }
        &.s-m_50 {
            max-height: 50px;
            min-height: 50px;
            line-height: 50px;
        }
        &.f-m_13 {
            font-size: 13px;
        }
        &.f-m_15 {
            font-size: 15px;
        }
    }
}
