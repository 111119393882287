html {
    font-size: 13px;
    line-height: 1.4;
    color: var(--color-text-primary);
}

a {
    color: var(--color-text-primary);
}

em {
    font-style: italic;
}
