:root {
    --font-0: 10px;
    --font-1: 11px;
    --font-2: 13px;
    --font-3: 15px;
    --font-4: 19px;
    --font-5: 22px;
    --font-6: 30px;
    --font-7: 40px;
    --font-8: 48px;

    --font-producttag: 12px;
    --font-button-mobile: 10px;
    --font-button-mobile-link: 10px;
    --font-button-desktop: 11px;

    --font-warning-footer-desktop: 24px;
    --font-warning-footer-mobile: 14px;

    --font-warning-article-desktop: 24px;
    --font-warning-article-mobile: 13px;

    /// 4px
    --offset-1: 4px;
    /// 8px
    --offset-2: 8px;
    /// 12px
    --offset-3: 12px;
    /// 16px
    --offset-4: 16px;
    /// 20px
    --offset-5: 20px;
    --offset-6: 24px;
    --offset-7: 28px;
    --offset-8: 32px;
    --offset-9: 36px;
    --offset-10: 40px;
    --offset-11: 44px;
    --offset-12: 48px;
    --offset-13: 52px;
    --offset-14: 56px;
    --offset-15: 60px;
    --offset-16: 64px;
    --offset-17: 68px;
    --offset-18: 72px;
    --offset-19: 76px;
    --offset-20: 80px;
}
