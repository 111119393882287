@use "var";

/// Чтобы не писать постоянно медиа запрос с размерами
///
/// @param {string} $size - Размер медиа-запроса (s, m, l, xl)
$media-sizes: (
    "s": var.$media-s,
    "m": var.$media-m,
    "l": var.$media-l,
    "xl": var.$media-xl,
);

@mixin media($size) {
    @if map-has-key($media-sizes, $size) {
        @media (min-width: map-get($media-sizes, $size)) {
            @content;
        }
    }
}
